import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        // Construir la URL con credenciales por GET
        const customUrl = `https://pedidos.ayvgas.cl/auth/login.php?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

        try {
            const response = await fetch(customUrl);

            if (!response.ok) {
                setError('Error en la solicitud. Intenta más tarde.');
                return;
            }

            const data = await response.json();
            if (data.success) {
                alert(data.message || 'Inicio de sesión exitoso');

                // Guardar el token y el role en localStorage si se devuelven
                if (data.token) {
                    localStorage.setItem('token', data.token);
                }
                if (data.role) {
                    localStorage.setItem('role', data.role);
                }

                // Redirigir al dashboard
                navigate('/welcome/dashboard');
            } else {
                setError(data.message || 'Credenciales incorrectas.');
            }
        } catch (err) {
            console.error('Error al conectar con el servidor:', err);
            setError('Error de conexión con el servidor.');
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', textAlign: 'center', padding: '20px' }}>
            <h2>Iniciar Sesión</h2>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Usuario:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                        }}
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label style={{ display: 'block', marginBottom: '5px' }}>Contraseña:</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                        }}
                    />
                    <div style={{ marginTop: '10px', textAlign: 'left' }}>
                        <label>
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={(e) => setShowPassword(e.target.checked)}
                                style={{ marginRight: '5px' }}
                            />
                            Mostrar contraseña
                        </label>
                    </div>
                </div>
                {error && (
                    <p style={{ color: 'red', marginBottom: '15px' }}>{error}</p>
                )}
                <button
                    type="submit"
                    style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#4caf50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px',
                    }}
                >
                    Ingresar
                </button>
            </form>
        </div>
    );
}

export default LoginForm;
