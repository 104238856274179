// PedidosRealizados.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PedidosRealizados() {
  const [pedidos, setPedidos] = useState([]);

  useEffect(() => {
    axios
      .get('https://pedidos.ayvgas.cl/telegram/getOrders.php')
      .then((response) => {
        setPedidos(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener los pedidos:', error);
      });
  }, []);

  return (
    <div>
      <h2>Pedidos Realizados</h2>
      <table>
        <thead>
          <tr>
            <th>ID de Usuario</th>
            <th>Detalles del Pedido</th>
            <th>Fecha y Hora</th>
          </tr>
        </thead>
        <tbody>
          {pedidos.map((pedido, index) => (
            <tr key={index}>
              <td>{pedido.user_id}</td>
              <td>{pedido.order_details}</td>
              <td>{pedido.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PedidosRealizados;
