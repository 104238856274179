// src/pages/Dashboard.jsx
import React from 'react';

function Dashboard() {
    return (
        <div>
            <h1>Dashboard</h1>
            <p>Bienvenido al dashboard.</p>
        </div>
    );
}

export default Dashboard;
