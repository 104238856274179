// src/pages/Moviles.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/Moviles.css";

function Moviles() {
    const [movil, setMovil] = useState("");
    const [numeroTelefono, setNumeroTelefono] = useState("");
    const [chatIdTelegram, setChatIdTelegram] = useState("");
    const [contactos, setContactos] = useState([]);
    const [editIndex, setEditIndex] = useState(null); // Nuevo estado para manejar el índice a editar

    // Obtener los datos iniciales
    useEffect(() => {
        const fetchContactos = async () => {
            try {
                const response = await axios.get("https://pedidos.ayvgas.cl/pedidos/contactos.json");
                setContactos(response.data);
            } catch (error) {
                console.error("Error al obtener los contactos:", error);
            }
        };
        fetchContactos();
    }, []);

    // Manejar envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();

        const datos = {
            movil,
            numero_telefono: numeroTelefono,
            chat_id_telegram: chatIdTelegram,
        };

        if (editIndex !== null) {
            // Editar contacto existente
            try {
                const response = await axios.post("https://pedidos.ayvgas.cl/pedidos/editar_contacto.php", {
                    index: editIndex,
                    ...datos,
                });
                alert(response.data.mensaje);
                const updatedContactos = [...contactos];
                updatedContactos[editIndex] = datos;
                setContactos(updatedContactos);
                setEditIndex(null); // Resetear el índice de edición
            } catch (error) {
                console.error("Hubo un error al editar el contacto:", error);
                alert("Hubo un error al editar el contacto");
            }
        } else {
            // Agregar nuevo contacto
            try {
                const response = await axios.post("https://pedidos.ayvgas.cl/pedidos/insertar_contacto.php", datos);
                alert(response.data.mensaje);
                setContactos([...contactos, datos]); // Actualizar la lista localmente
            } catch (error) {
                console.error("Hubo un error al agregar el contacto:", error);
                alert("Hubo un error al agregar el contacto");
            }
        }

        // Limpiar el formulario
        setMovil("");
        setNumeroTelefono("");
        setChatIdTelegram("");
    };

    // Manejar clic en el botón "Editar"
    const handleEdit = (index) => {
        const contacto = contactos[index];
        setMovil(contacto.movil);
        setNumeroTelefono(contacto.numero_telefono);
        setChatIdTelegram(contacto.chat_id_telegram);
        setEditIndex(index); // Establecer el índice para edición
    };

    // Manejar eliminación de un contacto
    const handleDelete = async (index) => {
        if (!window.confirm("¿Estás seguro de que deseas eliminar este contacto?")) return;

        try {
            const response = await axios.post("https://pedidos.ayvgas.cl/pedidos/eliminar_contacto.php", { index });
            alert(response.data.mensaje);
            const updatedContactos = [...contactos];
            updatedContactos.splice(index, 1);
            setContactos(updatedContactos);
        } catch (error) {
            console.error("Hubo un error al eliminar el contacto:", error);
            alert("Hubo un error al eliminar el contacto");
        }
    };

    return (
        <div className="moviles-container">
            <h2>{editIndex !== null ? "Editar Contacto" : "Agregar Nuevo Móvil"}</h2>
            <form onSubmit={handleSubmit} className="moviles-form">
                <div>
                    <label>Movil:</label>
                    <input
                        type="text"
                        value={movil}
                        onChange={(e) => setMovil(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Número de Teléfono:</label>
                    <input
                        type="text"
                        value={numeroTelefono}
                        onChange={(e) => setNumeroTelefono(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Chat ID de Telegram:</label>
                    <input
                        type="text"
                        value={chatIdTelegram}
                        onChange={(e) => setChatIdTelegram(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn-agregar">
                    {editIndex !== null ? "Guardar Cambios" : "Agregar"}
                </button>
            </form>

            <h3>Lista de Contactos</h3>
            <table className="contactos-table">
                <thead>
                    <tr>
                        <th>Movil</th>
                        <th>Número de Teléfono</th>
                        <th>Chat ID de Telegram</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {contactos.map((contacto, index) => (
                        <tr key={index}>
                            <td>{contacto.movil}</td>
                            <td>{contacto.numero_telefono}</td>
                            <td>{contacto.chat_id_telegram}</td>
                            <td className="actions-cell">
                                <button className="btn-editar" onClick={() => handleEdit(index)}>
                                    Editar
                                </button>
                                <button className="btn-borrar" onClick={() => handleDelete(index)}>
                                    Borrar
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Moviles;
