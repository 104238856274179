import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import LoginForm from './components/LoginForm';
import Dashboard from './pages/Dashboard';
import Pedidos from './pages/Pedidos';
import PedidosHistoricos from './pages/PedidosHistoricos';
import Moviles from './pages/Moviles';
import EnviarMensajesTelegram from './pages/EnviarMensajesTelegram';
import Mapa from './pages/Mapa';
import UsuariosRegistrados from './telegram/UsuariosRegistrados';
import PedidosRealizados from './telegram/PedidosRealizados';
import GestionUsuarios from './pages/GestionUsuarios';
import ProtectedRoute from './ProtectedRoute'; // Importar nuestro nuevo componente

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginForm />} />

                {/* Rutas protegidas */}
                <Route path="/welcome/*" element={
                    <ProtectedRoute> 
                        <Layout />
                    </ProtectedRoute>
                }>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="pedidos" element={<Pedidos />} />
                    <Route path="pedidos-historicos" element={<PedidosHistoricos />} />
                    <Route path="moviles" element={<Moviles />} />

                    {/* Esta ruta solo para Administradores */}
                    <Route path="enviar-mensajes" element={
                        <ProtectedRoute roles={['Administrador']}>
                            <EnviarMensajesTelegram />
                        </ProtectedRoute>
                    } />

                    {/* Esta ruta solo para Administradores */}
                    <Route path="gestion-usuarios" element={
                        <ProtectedRoute roles={['Administrador']}>
                            <GestionUsuarios />
                        </ProtectedRoute>
                    } />

                    {/* Rutas accesibles para ambos roles */}
                    <Route path="mapa/:ciudad" element={<Mapa />} />
                    <Route path="usuarios-registrados" element={<UsuariosRegistrados />} />
                    <Route path="pedidos-realizados" element={<PedidosRealizados />} />
                </Route>

                <Route path="*" element={<div>404 - Página no encontrada</div>} />
            </Routes>
        </Router>
    );
}

export default App;
