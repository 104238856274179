// mapa.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import MapaCiudad from './MapaCiudad';

function Mapa() {
    const { ciudad } = useParams();

    return <MapaCiudad ciudad={ciudad} />;
}

export default Mapa;
