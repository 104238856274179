import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Sidebar.css';

function Sidebar() {
    const navigate = useNavigate();
    const role = localStorage.getItem('role') || 'Supervisor'; // Valor predeterminado

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        alert('Sesión cerrada correctamente.');
        navigate('/'); // Redirige al login
    };

    // Definir las opciones disponibles según rol
    const opcionesComunes = [
        { nombre: 'Dashboard', ruta: '/welcome/dashboard' },
        { nombre: 'Pedidos', ruta: '/welcome/pedidos' },
        { nombre: 'Pedidos Históricos', ruta: '/welcome/pedidos-historicos' },
        { nombre: 'Móviles', ruta: '/welcome/moviles' },
        { nombre: 'Mapa', ruta: '/welcome/mapa/ciudad' } // Ejemplo, si deseas mostrar el mapa
    ];

    // Opciones solo para administrador
    const opcionesAdmin = [
        { nombre: 'Enviar Mensajes', ruta: '/welcome/enviar-mensajes' },
        { nombre: 'Gestión de Usuarios', ruta: '/welcome/gestion-usuarios' }
    ];

    const opciones = role === 'Administrador'
        ? [...opcionesComunes, ...opcionesAdmin]
        : opcionesComunes;

    return (
        <div className="sidebar">
            <h2>Menú</h2>
            <ul>
                {opciones.map((op) => (
                    <li key={op.nombre}>
                        <NavLink
                            to={op.ruta}
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {op.nombre}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <button className="logout-btn" onClick={handleLogout}>
                Cerrar Sesión
            </button>
        </div>
    );
}

export default Sidebar;
