import React, { useState, useEffect } from 'react';
import '../styles/GestionUsuarios.css';

const paginasDisponibles = {
    Administrador: [
        'Dashboard',
        'Pedidos',
        'Pedidos Históricos',
        'Enviar Mensajes',
        'Móviles',
        'Gestión de Usuarios',
    ],
    Supervisor: ['Dashboard', 'Pedidos', 'Pedidos Históricos'],
};

function GestionUsuarios() {
    const [usuarios, setUsuarios] = useState([]);
    const [nuevoUsuario, setNuevoUsuario] = useState({ username: '', password: '', role: 'Supervisor' });
    const [editandoUsuario, setEditandoUsuario] = useState(null);
    const [mostrarContraseña, setMostrarContraseña] = useState(false);

    useEffect(() => {
        cargarUsuarios();
    }, []);

    const cargarUsuarios = async () => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/auth/get_usuarios.php', { cache: 'no-store' });
            const data = await response.json();
            setUsuarios(data);
        } catch (error) {
            console.error('Error al cargar los usuarios:', error);
        }
    };

    const handleUsuarioChange = (e) => {
        const { name, value } = e.target;
        setNuevoUsuario({ ...nuevoUsuario, [name]: value });
    };

    const toggleMostrarContraseña = () => {
        setMostrarContraseña((prevState) => !prevState);
    };

    const guardarUsuario = async (e) => {
        e.preventDefault();

        if (!nuevoUsuario.username || !nuevoUsuario.password) {
            alert('Por favor, completa todos los campos.');
            return;
        }

        try {
            const response = await fetch('https://pedidos.ayvgas.cl/auth/save_usuario.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(nuevoUsuario),
            });

            if (!response.ok) {
                const errorData = await response.text();
                console.error('Error en la respuesta del servidor:', errorData);
                throw new Error(`Error del servidor: ${response.status} - ${response.statusText}`);
            }

            const result = await response.json();

            if (result.success) {
                alert(result.message || 'Usuario guardado exitosamente.');
                setNuevoUsuario({ username: '', password: '', role: 'Supervisor' });
                setEditandoUsuario(null);
                cargarUsuarios();
            } else {
                alert(result.message || 'Error al guardar el usuario.');
            }
        } catch (error) {
            console.error('Error al guardar el usuario:', error);
            alert(error.message || 'Error al guardar el usuario.');
        }
    };

    const editarUsuario = (usuario) => {
        setNuevoUsuario(usuario);
        setEditandoUsuario(usuario.id);
    };

    const eliminarUsuario = async (id) => {
        if (!window.confirm('¿Estás seguro de eliminar este usuario?')) return;

        try {
            const response = await fetch('https://pedidos.ayvgas.cl/auth/delete_usuario.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id }),
            });

            const result = await response.json();

            if (result.success) {
                alert('Usuario eliminado exitosamente.');
                cargarUsuarios();
            } else {
                alert(result.message || 'Error al eliminar el usuario.');
            }
        } catch (error) {
            console.error('Error al eliminar el usuario:', error);
            alert('Error al eliminar el usuario.');
        }
    };

    return (
        <div className="gestion-usuarios-container">
            <h1>Gestión de Usuarios</h1>

            <section>
                <h2>Usuarios</h2>
                <table className="gestion-usuarios-tabla">
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Rol</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((usuario) => (
                            <tr key={usuario.id}>
                                <td>{usuario.username}</td>
                                <td>{usuario.role}</td>
                                <td>
                                    <button onClick={() => editarUsuario(usuario)}>Editar</button>
                                    <button onClick={() => eliminarUsuario(usuario.id)}>Eliminar</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h3>{editandoUsuario !== null ? 'Editar Usuario' : 'Agregar Usuario'}</h3>
                <form className="gestion-usuarios-form" onSubmit={guardarUsuario}>
                    <input
                        type="text"
                        name="username"
                        placeholder="Nombre de Usuario"
                        value={nuevoUsuario.username}
                        onChange={handleUsuarioChange}
                    />
                    <div className="password-container">
                        <input
                            type={mostrarContraseña ? 'text' : 'password'}
                            name="password"
                            placeholder="Contraseña"
                            value={nuevoUsuario.password}
                            onChange={handleUsuarioChange}
                        />
                        <label>
                            <input type="checkbox" onChange={toggleMostrarContraseña} /> Mostrar Contraseña
                        </label>
                    </div>
                    <select name="role" value={nuevoUsuario.role} onChange={handleUsuarioChange}>
                        {Object.keys(paginasDisponibles).map((role, index) => (
                            <option key={index} value={role}>
                                {role}
                            </option>
                        ))}
                    </select>
                    <button type="submit">{editandoUsuario !== null ? 'Guardar Cambios' : 'Agregar Usuario'}</button>
                </form>
            </section>
        </div>
    );
}

export default GestionUsuarios;
