import React, { useState } from 'react';
import axios from 'axios';

function EnviarMensajesTelegram() {
    const [message, setMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [recipient, setRecipient] = useState('group'); // 'group', 'specific', 'all'
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleSendMessage = async () => {
        try {
            if (!message.trim() && !photo) {
                setResponseMessage('Debes escribir un mensaje o adjuntar una imagen.');
                return;
            }

            if (photo) {
                if (!['image/jpeg', 'image/png', 'image/gif'].includes(photo.type)) {
                    setResponseMessage('El tipo de archivo no es válido. Solo se permiten imágenes JPEG, PNG o GIF.');
                    return;
                }

                if (photo.size > 5 * 1024 * 1024) {
                    setResponseMessage('La foto no puede superar los 5 MB.');
                    return;
                }
            }

            setLoading(true);

            const formData = new FormData();
            formData.append('message', message);
            if (photo) {
                formData.append('photo', photo);
            }
            formData.append('recipient', recipient);

            const response = await axios.post('https://pedidos.ayvgas.cl/telegram/sendmessage.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setResponseMessage(response.data.message || 'Mensaje enviado correctamente.');
            setMessage('');
            setPhoto(null);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Error al enviar el mensaje.';
            setResponseMessage(errorMessage);
            console.error('Error al enviar el mensaje:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Enviar Mensajes a Telegram</h2>

            {responseMessage && (
                <div style={styles.response}>
                    <p>{responseMessage}</p>
                </div>
            )}

            <div style={styles.content}>
                {/* Formulario de entrada */}
                <div style={styles.form}>
                    <textarea
                        style={styles.textarea}
                        placeholder="Escribe tu mensaje aquí"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>

                    <input
                        style={styles.fileInput}
                        type="file"
                        accept="image/*"
                        onChange={(e) => setPhoto(e.target.files[0])}
                    />

                    <select
                        style={styles.select}
                        value={recipient}
                        onChange={(e) => setRecipient(e.target.value)}
                    >
                        <option value="group">Enviar al grupo</option>
                        <option value="specific">Enviar a un ID específico</option>
                        <option value="all">Enviar a todos los usuarios</option>
                    </select>

                    <button style={styles.button} onClick={handleSendMessage} disabled={loading}>
                        {loading ? 'Enviando...' : 'Enviar Mensaje'}
                    </button>
                </div>

                {/* Vista previa del chat */}
                <div style={styles.phone}>
                    {/* Header del chat */}
                    <div style={styles.chatHeader}>
                        <h3 style={styles.chatTitle}>Vista Previa</h3>
                    </div>

                    {/* Mensajes del chat */}
                    <div style={styles.chatContainer}>
                        {/* Vista previa del mensaje que se está enviando */}
                        {(photo || message) && (
                            <div style={styles.messageBubbleOutgoing}>
                                {photo && (
                                    <img
                                        style={styles.messageImage}
                                        src={URL.createObjectURL(photo)}
                                        alt="Previsualización"
                                    />
                                )}
                                {message && <p>{message}</p>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        fontSize: '1.5em',
        color: '#333',
    },
    response: {
        marginBottom: '10px',
        padding: '10px',
        backgroundColor: '#e6f7ff',
        border: '1px solid #91d5ff',
        borderRadius: '5px',
        color: '#0050b3',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    form: {
        width: '48%',
        maxWidth: '500px',
    },
    textarea: {
        width: '100%',
        height: '80px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginBottom: '15px',
        fontSize: '1em',
        resize: 'none',
    },
    fileInput: {
        width: '100%',
        marginBottom: '15px',
    },
    select: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginBottom: '15px',
    },
    button: {
        width: '100%',
        padding: '10px',
        backgroundColor: '#4caf50',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1em',
    },
    phone: {
        width: '48%',
        height: '640px',
        borderRadius: '30px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    },
    chatHeader: {
        backgroundColor: '#0088cc',
        color: '#fff',
        padding: '15px',
        textAlign: 'center',
    },
    chatTitle: {
        margin: 0,
        fontSize: '1.2em',
    },
    chatContainer: {
        padding: '10px',
        backgroundColor: '#e5ddd5',
        height: 'calc(100% - 70px)',
        overflowY: 'auto',
    },
    messageBubbleOutgoing: {
        maxWidth: '70%',
        margin: '10px 0 10px auto',
        padding: '10px',
        backgroundColor: '#dcf8c6',
        borderRadius: '10px',
        textAlign: 'left',
        wordWrap: 'break-word',
    },
    messageImage: {
        marginBottom: '10px',
        maxWidth: '100%',
        borderRadius: '5px',
    },
};

export default EnviarMensajesTelegram;
