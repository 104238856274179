import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// Importaciones de imágenes y estilos
import img1 from '../images/1.png';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/4.png';
import img5 from '../images/5.png';
import nuevoBotonImg from '../images/pngegg.png';
import editarImg from '../images/editar.png';
import eliminarImg from '../images/eliminar.png';
import cambiarEstadoImg from '../images/cambiar.png';
import mapaImg from '../images/mapa.png';
import telegramImg from '../images/telegram.webp';
import '../styles/Pedidos.css';
import { useNavigate } from 'react-router-dom';


dayjs.extend(customParseFormat); // Extiende dayjs para manejar formatos personalizados

// Opciones de productos que estarán disponibles en la interfaz
const opcionesProductos = [
    'Gas 15kg Normal',
    'Gas 45kg Normal',
    'Gas 5kg Normal',
    'Gas 11kg Normal',
    'Gas 15kg VMA',
];

// Opciones de estado que pueden tener los pedidos
const opcionesEstado = ['Despacho', 'Entregado', 'Pendiente', 'Anulado'];

// Opciones de ciudades que pertenecen al Call Center
const opcionesCiudad = ['Arica', 'Antofagasta']; // Lista desplegable para Call Center

// Opciones para el campo Origen
const opcionesOrigen = ['Call Center','DUO','RR SS','Telegram' ];

// Componente principal de Pedidos
function Pedidos() {
    const navigate = useNavigate();
    // Estado para manejar la lista de pedidos cargados
    const [pedidos, setPedidos] = useState([]);

    // Estado para manejar la creación de nuevas filas de pedidos
    const [nuevaFila, setNuevaFila] = useState({
        campo_2: generarNumeroPedido(),
        campo_4: '',
        campo_7: '',
        campo_8: '', // Producto1
        campo_9: '', // Producto2
        campo_13: '', // Producto3
        campo_14: '', // Producto4
        campo_10: '',
        campo_11: '',
        campo_12: '',
        campo_6: 'Despacho',
        campo_yy: '',
    });

    const [contadorCampo1, setContadorCampo1] = useState(1); // Estado para campo_1

    const [editando, setEditando] = useState(null); // Control de la fila que se está editando
    const [estaEditando, setEstaEditando] = useState(false); // Controla si se está editando o no
    const [editandoEstado, setEditandoEstado] = useState(null); // Control de la fila que está cambiando su estado
    const [errores, setErrores] = useState(''); // Manejo de errores para campos incompletos
    const inputDireccionRef = useRef(null); // Referencia al input de dirección para usar con Google Places API

    // Función que genera un número aleatorio de pedido
    function generarNumeroPedido() {
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        return `90${randomNumber}`; // Correct
    }

    const cargarDatos = async () => {
        try {
            const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
                fetch('https://pedidos.ayvgas.cl/pedidos/pedidos.json', { cache: 'no-store' }).then((res) => {
                    if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos.json`);

                    return res.json();
                }),
                fetch('https://pedidos.ayvgas.cl/pedidos/pedidos_antofagasta.json', { cache: 'no-store' }).then((res) => {
                    if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos_antofagasta.json`);

                    return res.json();
                }),
                fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then((res) => {
                    if (!res.ok) throw new Error(`Error ${res.status} al cargar pedido_callcenter.json`);

                    return res.json();
                }),
            ]);

            // Verificar que los datos obtenidos sean arrays
            const pedidosAricaArray = Array.isArray(pedidosArica)
                ? pedidosArica
                : Object.keys(pedidosArica).map((key) => pedidosArica[key]);
            const pedidosAntofagastaArray = Array.isArray(pedidosAntofagasta)
                ? pedidosAntofagasta
                : Object.keys(pedidosAntofagasta).map((key) => pedidosAntofagasta[key]);
            const pedidosCallCenterArray = Array.isArray(pedidosCallCenter)
                ? pedidosCallCenter
                : Object.keys(pedidosCallCenter).map((key) => pedidosCallCenter[key]);

            const pedidosAricaConCiudad = pedidosAricaArray.map((pedido) => ({
                ...pedido,
                campo_yy: 'Arica',
                campo_12: pedido.campo_12 || 'DUO',
                origen: 'pedidos.json', // Indicar origen
            }));

            const pedidosAntofagastaConCiudad = pedidosAntofagastaArray.map((pedido) => ({
                ...pedido,
                campo_yy: 'Antofagasta',
                campo_12: pedido.campo_12 || 'Call Center',
                origen: 'pedidos_antofagasta.json', // Indicar origen
            }));

            const pedidosCallCenterConCiudad = pedidosCallCenterArray.map((pedido) => ({
                ...pedido,
                campo_yy: pedido.campo_yy || 'Arica',
                campo_12: pedido.campo_12 || 'DUO',
                origen: 'pedido_callcenter.json', // Indicar origen
            }));

            // Combinar los pedidos de todas las ciudades
            const pedidosCombinados = [
                ...pedidosAricaConCiudad,
                ...pedidosAntofagastaConCiudad,
                ...pedidosCallCenterConCiudad,
            ];

            // Filtrar pedidos duplicados
            const pedidosUnicos = pedidosCombinados.reduce((acc, current) => {
                const x = acc.find((item) => item.campo_2 === current.campo_2);
                return !x ? acc.concat([current]) : acc;
            }, []);

            setPedidos(pedidosUnicos);
        } catch (error) {
            console.error('Error al cargar los pedidos:', error);
        }
    };

    // Función que actualiza solo las filas que hayan cambiado
    const actualizarSoloFila = async () => {
        try {
            const [pedidosArica, pedidosAntofagasta, pedidosCallCenter] = await Promise.all([
                fetch('https://pedidos.ayvgas.cl/pedidos/pedidos.json', { cache: 'no-store' }).then(async (res) => {
                    if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos.json`);

                    const text = await res.text();
                    try {
                        return JSON.parse(text);
                    } catch (error) {
                        console.error('Respuesta no es JSON válida para pedidos.json:', text);
                        throw error;
                    }
                }),
                fetch('https://pedidos.ayvgas.cl/pedidos/pedidos_antofagasta.json', { cache: 'no-store' }).then(
                    async (res) => {
                        if (!res.ok) throw new Error(`Error ${res.status} al cargar pedidos_antofagasta.json`);

                        const text = await res.text();
                        try {
                            return JSON.parse(text);
                        } catch (error) {
                            console.error('Respuesta no es JSON válida para pedidos_antofagasta.json:', text);
                            throw error;
                        }
                    }
                ),
                fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter.json', { cache: 'no-store' }).then(
                    async (res) => {
                        if (!res.ok) throw new Error(`Error ${res.status} al cargar pedido_callcenter.json`);

                        const text = await res.text();
                        try {
                            return JSON.parse(text);
                        } catch (error) {
                            console.error('Respuesta no es JSON válida para pedido_callcenter.json:', text);
                            throw error;
                        }
                    }
                ),
            ]);

            // Procesamiento de pedidos como antes (sin cambios)
            const pedidosAricaArray = Array.isArray(pedidosArica)
                ? pedidosArica
                : Object.keys(pedidosArica).map((key) => pedidosArica[key]);
            const pedidosAntofagastaArray = Array.isArray(pedidosAntofagasta)
                ? pedidosAntofagasta
                : Object.keys(pedidosAntofagasta).map((key) => pedidosAntofagasta[key]);
            const pedidosCallCenterArray = Array.isArray(pedidosCallCenter)
                ? pedidosCallCenter
                : Object.keys(pedidosCallCenter).map((key) => pedidosCallCenter[key]);

            // Continuar con el procesamiento como antes
            const pedidosAricaConCiudad = pedidosAricaArray.map((pedido) => ({ ...pedido, campo_yy: 'Arica' }));
            const pedidosAntofagastaConCiudad = pedidosAntofagastaArray.map((pedido) => ({
                ...pedido,
                campo_yy: 'Antofagasta',
            }));
            const pedidosCallCenterConCiudad = pedidosCallCenterArray.map((pedido) => ({
                ...pedido,
                campo_yy: pedido.campo_yy || 'Arica',
            }));

            const pedidosCombinados = [
                ...pedidosAricaConCiudad,
                ...pedidosAntofagastaConCiudad,
                ...pedidosCallCenterConCiudad,
            ];

            const pedidosUnicos = pedidosCombinados.reduce((acc, current) => {
                const x = acc.find((item) => item.campo_2 === current.campo_2);
                return !x ? acc.concat([current]) : acc;
            }, []);

            setPedidos(pedidosUnicos);
        } catch (error) {
            console.error('Error al actualizar la fila del pedido:', error);
        }
    };

    // Función para mover un pedido a histórico al cambiar su estado
    const moverPedidoAHistoricoPHP = async (pedido) => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/mover_pedidosduo_a_historico.php', {
                // Cambiar URL a servidor correcto
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });

            if (!response.ok) {
                throw new Error('Error al mover el pedido a histórico');
            }
        } catch (error) {
            console.error('Error al mover el pedido a histórico:', error);
        }
    };

    // Intervalo de actualización del cronómetro cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
            setPedidos((prevPedidos) =>
                prevPedidos.map((pedido) => {
                    const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
                    return {
                        ...pedido,
                        tiempoFormateado: formatearTiempo(diferenciaSegundos),
                        semaforoImagen: obtenerSemaforoYColor(diferenciaMinutos).imagen,
                    };
                })
            );
        }, 1000);

        // Limpiar el intervalo cuando se desmonte el componente
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!estaEditando) {
                actualizarSoloFila(); // Solo actualiza si no estás editando
            }
        }, 5000);

        return () => clearInterval(intervalId); // Limpiar el intervalo cuando se desmonte el componente
    }, [estaEditando]); // Agregar esta dependencia

    // Inicializar el autocompletado de Google Places para las direcciones
    useEffect(() => {
        const loadGoogleMapsScript = () => {
            const existingScript = document.getElementById('googleMaps');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src =
                    'https://maps.googleapis.com/maps/api/js?key=AIzaSyADoYPO9Uw_rs9ZFF301cBVs7PO_GgAESU&libraries=places&language=es';
                script.id = 'googleMaps';
                script.async = true; // Cargar de manera asíncrona
                document.body.appendChild(script);

                script.onload = () => {
                    initAutocomplete();
                };
            } else {
                initAutocomplete();
            }
        };

        // Configurar el autocompletado para el campo de direcciones
        const initAutocomplete = () => {
            if (window.google) {
                const autocomplete = new window.google.maps.places.Autocomplete(inputDireccionRef.current, {
                    types: ['address'],
                    componentRestrictions: { country: 'cl' },
                });
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        setNuevaFila((prevFila) => ({ ...prevFila, campo_7: place.formatted_address }));
                    }
                });
            }
        };

        loadGoogleMapsScript(); // Cargar el script de Google Maps
    }, []);

    // Agregamos este useEffect para cargar el último campo_1 del histórico
    useEffect(() => {
        const cargarUltimoCampo1 = async () => {
            try {
                const response = await fetch('https://pedidos.ayvgas.cl/pedidos/pedido_callcenter_historico.json', {
                    cache: 'no-store',
                });
                if (!response.ok) throw new Error(`Error ${response.status} al cargar el archivo histórico`);

                const historico = await response.json();
                const ultimoCampo1 =
                    historico.length > 0 ? Math.max(...historico.map((pedido) => pedido.campo_1)) : 0;
                setContadorCampo1(ultimoCampo1 + 1);
            } catch (error) {
                console.error('Error al cargar el último campo_1:', error);
            }
        };

        cargarUltimoCampo1();
    }, []);

    const guardarEnArchivo = async (pedido) => {
        console.log('Intentando guardar pedido:', pedido);
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/guardar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });
            if (!response.ok) {
                throw new Error('Error al guardar el pedido');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const enviarPedidoAHistorico = async (pedido) => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/mover_a_historico.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });

            if (!response.ok) {
                throw new Error('Error al mover el pedido a histórico');
            }
            console.log(`Pedido ${pedido.campo_2} movido a histórico`);
        } catch (error) {
            console.error('Error al mover el pedido a histórico:', error);
        }
    };

    const actualizarPedido = async (pedido) => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/actualizar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(pedido),
            });
            if (!response.ok) {
                throw new Error('Error al actualizar el pedido');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Eliminar un pedido
    const eliminarPedido = async (pedido) => {
        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/eliminar_pedido.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    campo_1: pedido.campo_1, // Enviamos campo_1 en lugar de campo_2
                }),
            });
            if (!response.ok) {
                throw new Error('Error al eliminar el pedido');
            }
        } catch (error) {
            console.error('Error al eliminar el pedido:', error);
        }
    };

    const handleEstadoChange = async (e, index) => {
        const nuevoEstado = e.target.value;
        const nuevosPedidos = [...pedidos];
        const pedidoModificado = { ...nuevosPedidos[index], campo_6: nuevoEstado };

        try {
            // Primero, actualizar el pedido en el archivo de origen
            await actualizarPedido(pedidoModificado);

            // Solo mover a histórico si el estado cambió a 'Anulado' o 'Entregado'
            if (nuevoEstado === 'Anulado' || nuevoEstado === 'Entregado') {
                const tiempoTotal = detenerTiempo(pedidoModificado.campo_5); // Detener el tiempo
                pedidoModificado.campo_xx = tiempoTotal; // Guardar el tiempo como nuevo campo
                await enviarPedidoAHistorico(pedidoModificado); // Mover a histórico
            }

            await cargarDatos(); // Recargar los datos después de mover a histórico o actualizar el estado
        } catch (error) {
            console.error('Error al cambiar el estado del pedido:', error);
        }

        setEditandoEstado(null); // Cerrar la edición después de cambiar el estado
    };

    // Validar que los campos obligatorios estén completos
    const validarCampos = (fila) => {
        if (
            !fila.campo_4 ||
            !fila.campo_7 ||
            !fila.campo_8 ||
            !fila.campo_11 ||
            !fila.campo_yy ||
            !fila.campo_12
        ) {
            return false; // Si falta algún campo obligatorio
        }
        return true; // Todos los campos están completos
    };

    // Detener el tiempo cuando el estado cambie a 'Anulado' o 'Entregado'
    const detenerTiempo = (fechaPedido) => {
        const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
        const ahora = dayjs();
        const diferenciaSegundos = ahora.diff(fecha, 'second');
        return formatearTiempo(diferenciaSegundos);
    };

    // Calcular la diferencia de tiempo entre la fecha del pedido y el momento actual
    const calcularDiferenciaTiempo = (fechaPedido) => {
        const fecha = dayjs(fechaPedido, 'DD-MM-YYYY, HH:mm');
        const ahora = dayjs();
        const diferenciaMinutos = ahora.diff(fecha, 'minute');
        const diferenciaSegundos = ahora.diff(fecha, 'second');
        return { diferenciaMinutos, diferenciaSegundos };
    };

    // Formatear el tiempo en hh:mm:ss
    const formatearTiempo = (segundosTotales) => {
        const horas = Math.floor(segundosTotales / 3600);
        const minutos = Math.floor((segundosTotales % 3600) / 60);
        const segundos = segundosTotales % 60;
        return `${horas.toString().padStart(2, '0')}:${minutos
            .toString()
            .padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
    };

    // Obtener la imagen del semáforo y el color según el tiempo transcurrido
    const obtenerSemaforoYColor = (diferenciaMinutos) => {
        if (diferenciaMinutos < 6) return { imagen: img1, color: 'verde', nombre: 'Semáforo 1' };
        if (diferenciaMinutos < 12) return { imagen: img2, color: 'amarillo', nombre: 'Semáforo 2' };
        if (diferenciaMinutos < 18) return { imagen: img3, color: 'naranja', nombre: 'Semáforo 3' };
        if (diferenciaMinutos < 24) return { imagen: img4, color: 'rojo', nombre: 'Semáforo 4' };
        return { imagen: img5, color: 'morado', nombre: 'Semáforo 5' };
    };

    const handleNuevaFilaChange = (e) => {
        const { name, value } = e.target;

        setNuevaFila((prevFila) => ({
            ...prevFila,
            [name]: value,
        }));
    };

    const handleEditChange = (e, index) => {
        const { name, value } = e.target;
        const nuevosPedidos = [...pedidos];

        nuevosPedidos[index] = { ...nuevosPedidos[index], [name]: value };
        setPedidos(nuevosPedidos);
    };

    const guardarFila = async () => {
        if (!validarCampos(nuevaFila)) {
            setErrores('Por favor, completa todos los campos obligatorios.');
            return;
        }

        const nuevoPedido = {
            ...nuevaFila,
            campo_1: contadorCampo1, // Usamos contadorCampo1
            campo_5: dayjs().format('DD-MM-YYYY, HH:mm'),
            campo_7: nuevaFila.campo_7.toUpperCase(),
            campo_11: nuevaFila.campo_11.padStart(2, '0'),
            campo_13: nuevaFila.campo_13, // Producto3
            campo_14: nuevaFila.campo_14, // Producto4
        };

        try {
            await guardarEnArchivo(nuevoPedido);
            await cargarDatos();
            setContadorCampo1(contadorCampo1 + 1); // Incrementamos contadorCampo1
            setNuevaFila({
                campo_2: generarNumeroPedido(),
                campo_4: '',
                campo_7: '',
                campo_8: '',
                campo_9: '',
                campo_13: '', // Reinicia Producto3
                campo_14: '', // Reinicia Producto4
                campo_10: '',
                campo_11: '',
                campo_12: '',
                campo_6: 'Despacho',
                campo_yy: '',
            });
        } catch (error) {
            console.error('Error al guardar la fila:', error);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (editando === index && index !== -1) {
                handleGuardarEdicion(index);
            } else if (editandoEstado === index && index !== -1) {
                setEditandoEstado(null);
            } else {
                guardarFila(); // Llama a guardarFila cuando index es -1
            }
        }
    };

    const handleEditar = (index) => {
        setEditando(index);
        setEstaEditando(true); // Decir que estamos editando
    };

    const handleEliminar = async (index) => {
        const pedidoAEliminar = pedidos[index];
        const confirmacion = window.confirm("¿Estás seguro de que deseas eliminar este pedido?");
        if (!confirmacion) return;

        try {
            const response = await fetch('https://pedidos.ayvgas.cl/pedidos/eliminar_pedido.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ campo_2: pedidoAEliminar.campo_2 }), // Envía solo campo_2
            });

            if (response.ok) {
                // Remueve el pedido localmente después de la eliminación en el servidor
                setPedidos((prevPedidos) => prevPedidos.filter((_, i) => i !== index));
            } else {
                throw new Error('Error al eliminar el pedido');
            }
        } catch (error) {
            console.error('Error al eliminar el pedido:', error);
        }
    };


    const handleGuardarEdicion = async (index) => {
        if (!validarCampos(pedidos[index])) {
            setErrores('Por favor, completa todos los campos obligatorios.');
            return;
        }
        setErrores('');
        setEditando(null);
        setEstaEditando(false);

        // Agregar origen al pedido antes de enviar
        const pedidoActualizado = {
            ...pedidos[index],
            origen: pedidos[index].origen, // Agregar el campo 'origen'
        };

        try {
            await actualizarPedido(pedidoActualizado);
            await cargarDatos(); // Recargar los datos para reflejar los cambios
        } catch (error) {
            console.error('Error al actualizar el pedido:', error);
        }
    };

    const handleCambiarEstado = (index) => {
        setEditandoEstado(index); // Activar el modo edición de estado
    };

    const handleEnviarTelegram = async (index) => {
        const pedido = pedidos[index];
        const movil = pedido.campo_11; // Obtener el móvil del pedido
    
        try {
            // Cargar contactos
            const response = await fetch("https://pedidos.ayvgas.cl/pedidos/contactos.json");
            if (!response.ok) throw new Error("Error al cargar los contactos");
    
            const contactos = await response.json();
            const contacto = contactos.find((c) => c.movil === movil);
    
            if (!contacto) {
                alert(`No se encontró el chat_id_telegram para este móvil: ${movil}`);
                return;
            }
    
            const chatIdTelegram = contacto.chat_id_telegram;
    
            // Construir el mensaje
            const mensaje = [
                `*Pedido ${pedido.campo_2}:*`,
                `*Teléfono del Cliente:* ${pedido.campo_4 || "No definido"}`,
                `*Dirección:* ${pedido.campo_7 || "No definida"}`,
                "",
                pedido.campo_8 ? `*Producto1:* ${pedido.campo_8}` : "",
                pedido.campo_9 ? `*Producto2:* ${pedido.campo_9}` : "",
                pedido.campo_13 ? `*Producto3:* ${pedido.campo_13}` : "",
                pedido.campo_14 ? `*Producto4:* ${pedido.campo_14}` : "",
                "",
                `*Estado:* ${pedido.campo_6 || "No definido"}`,
                `*Ciudad:* ${pedido.campo_yy || "No definida"}`,
            ]
                .filter((line) => line)
                .join("\n");
    
            // Configurar el botón para contactar al cliente usando la URL redireccionada
            const tecladoEnLinea = {
                inline_keyboard: [
                    [
                        {
                            text: "📞 Contactar al Cliente",
                            url: `https://pedidos.ayvgas.cl/llamar.html?telefono=${encodeURIComponent(pedido.campo_4)}`, // URL redireccionada
                        },
                    ],
                ],
            };
    
            // Enviar el mensaje con el botón
            const TELEGRAM_BOT_TOKEN = "7463925088:AAHPDr0alFLRsp_2nAr429Oe-AzinIRKPqo";
            const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
    
            const sendMessageResponse = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    chat_id: chatIdTelegram,
                    text: mensaje,
                    parse_mode: "Markdown",
                    reply_markup: tecladoEnLinea,
                }),
            });
    
            const result = await sendMessageResponse.json();
            console.log("Telegram API Response:", result);
    
            if (!sendMessageResponse.ok) {
                console.error("Error al enviar el mensaje de Telegram:", result);
                throw new Error(result.description || "Error al enviar el mensaje");
            }
    
            alert("Mensaje enviado correctamente a Telegram");
        } catch (error) {
            console.error("Error al enviar el mensaje:", error.message);
            alert(`Hubo un error al enviar el mensaje: ${error.message}`);
        }
    };
    

    const handleNuevoBoton = (index) => {
        console.log(`Nuevo botón clicado en el índice: ${index}`);
        // Aquí puedes agregar la lógica que necesites para este botón
    };


    useEffect(() => {
        cargarDatos();
    }, []);

    return (
        <div>
            <h1>Pedidos Activos</h1>
            {errores && <p style={{ color: 'red' }}>{errores}</p>}
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>N° del Pedido</th>
                        <th>Semáforo</th>
                        <th>Teléfono</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>Dirección</th>
                        <th>Producto1</th>
                        <th>Producto2</th>
                        <th>Producto3</th> {/* Nuevo encabezado */}
                        <th>Producto4</th> {/* Nuevo encabezado */}
                        <th>Movil</th>
                        <th>Origen</th>
                        <th>Ciudad</th>
                        <th>Tiempo</th>
                    </tr>
                </thead>

                <tbody>
                    {pedidos.map((pedido, index) => {
                        const { diferenciaMinutos, diferenciaSegundos } = calcularDiferenciaTiempo(pedido.campo_5);
                        const { imagen: imagenSemaforo } = obtenerSemaforoYColor(diferenciaMinutos);

                        return (
                            <tr key={index}>
                                <td>{pedido.campo_1}</td>
                                <td>{pedido.campo_2}</td>
                                <td>
                                    <img src={imagenSemaforo} alt="Semáforo" width="50" />
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            type="text"
                                            name="campo_4"
                                            value={pedido.campo_4}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)} // Detectar Enter en teléfono
                                        />
                                    ) : (
                                        pedido.campo_4
                                    )}
                                </td>
                                <td>{pedido.campo_5}</td>
                                <td>
                                    {editandoEstado === index ? (
                                        <select
                                            value={pedido.campo_6}
                                            onChange={(e) => handleEstadoChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)} // Detectar Enter en select de estado
                                        >
                                            {opcionesEstado.map((estado, idx) => (
                                                <option key={idx} value={estado}>
                                                    {estado}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_6
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            ref={inputDireccionRef}
                                            type="text"
                                            name="campo_7"
                                            value={pedido.campo_7}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)} // Detectar Enter en dirección
                                        />
                                    ) : (
                                        pedido.campo_7
                                    )}
                                </td>
                                {/* Producto1 */}
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_8"
                                            value={pedido.campo_8}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{pedido.campo_8}</span> // Muestra como texto
                                    )}
                                </td>

                                {/* Producto2 */}
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_9"
                                            value={pedido.campo_9}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{pedido.campo_9}</span> // Muestra como texto
                                    )}
                                </td>

                                {/* Producto3 */}
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_13"
                                            value={pedido.campo_13}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{pedido.campo_13}</span> // Muestra como texto
                                    )}
                                </td>

                                {/* Producto4 */}
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_14"
                                            value={pedido.campo_14}
                                            onChange={(e) => handleEditChange(e, index)}
                                        >
                                            <option value="">Seleccionar</option>
                                            {opcionesProductos.map((producto, idx) => (
                                                <option key={idx} value={producto}>
                                                    {producto}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <span>{pedido.campo_14}</span> // Muestra como texto
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <input
                                            type="text"
                                            name="campo_11"
                                            value={pedido.campo_11}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)} // Detectar Enter en móvil
                                        />
                                    ) : (
                                        pedido.campo_11
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_12"
                                            value={pedido.campo_12}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                        >
                                            <option value="">Seleccionar Origen</option>
                                            {opcionesOrigen.map((origen, idx) => (
                                                <option key={idx} value={origen}>
                                                    {origen}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_12
                                    )}
                                </td>
                                <td>
                                    {editando === index ? (
                                        <select
                                            name="campo_yy"
                                            value={pedido.campo_yy}
                                            onChange={(e) => handleEditChange(e, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)} // Detectar Enter en ciudad
                                        >
                                            <option value="">Seleccionar Ciudad</option>
                                            {opcionesCiudad.map((ciudad, idx) => (
                                                <option key={idx} value={ciudad}>
                                                    {ciudad}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        pedido.campo_yy
                                    )}
                                </td>
                                <td>{pedido.tiempoFormateado}</td>

                                {/* Botones */}
                                <td style={{ display: 'flex', gap: '5px' }}>
                                    {editando === index ? (
                                        <img
                                            src={editarImg}
                                            alt="Guardar"
                                            width="30"
                                            onClick={async () => {
                                                await handleGuardarEdicion(index);
                                                cargarDatos(); // Refrescar la vista después de guardar
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src={editarImg}
                                            alt="Editar"
                                            width="30"
                                            onClick={() => handleEditar(index)}
                                        />
                                    )}
                                    <img
                                        src={eliminarImg}
                                        alt="Eliminar"
                                        width="30"
                                        onClick={async () => {
                                            await handleEliminar(index);
                                            cargarDatos(); // Refrescar la vista después de eliminar
                                        }}
                                    />
                                    <img
                                        src={cambiarEstadoImg}
                                        alt="Cambiar Estado"
                                        width="30"
                                        onClick={() => {
                                            if (editandoEstado === index) {
                                                setEditandoEstado(null); // Si ya está en modo edición, cerrar
                                            } else {
                                                handleCambiarEstado(index); // Activar la edición del estado
                                            }
                                        }}
                                    />

                                    <img
                                        src={telegramImg}
                                        alt="Telegram"
                                        width="30"
                                        onClick={() => handleEnviarTelegram(index)}
                                    />
                                    <img
                                        src={nuevoBotonImg}
                                        alt="Mapa"
                                        width="30"
                                        onClick={() => navigate(`/welcome/mapa/${pedido.campo_yy}`)} // Redirige correctamente
                                    />
                                </td>
                            </tr>
                        );
                    })}

                    {/* Nueva fila para agregar más pedidos */}
                    <tr>
                        <td>{contadorCampo1}</td>
                        <td>{nuevaFila.campo_2}</td>
                        <td></td>
                        <td>
                            <input
                                type="text"
                                name="campo_4"
                                value={nuevaFila.campo_4}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e)} // Detectar Enter en teléfono nueva fila
                            />
                        </td>
                        <td></td>
                        <td>Despacho</td>
                        <td>
                            <input
                                ref={inputDireccionRef}
                                type="text"
                                name="campo_7"
                                value={nuevaFila.campo_7}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e)} // Detectar Enter en dirección nueva fila
                            />
                        </td>
                        <td>
                            <select
                                name="campo_8"
                                value={nuevaFila.campo_8}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e, -1)}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, idx) => (
                                    <option key={idx} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        {/* Producto2 */}
                        <td>
                            <select
                                name="campo_9"
                                value={nuevaFila.campo_9}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e, -1)}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, idx) => (
                                    <option key={idx} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        {/* Producto3 */}
                        <td>
                            <select
                                name="campo_13"
                                value={nuevaFila.campo_13}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e, -1)}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, idx) => (
                                    <option key={idx} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        {/* Producto4 */}
                        <td>
                            <select
                                name="campo_14"
                                value={nuevaFila.campo_14}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e, -1)}
                            >
                                <option value="">Seleccionar</option>
                                {opcionesProductos.map((producto, idx) => (
                                    <option key={idx} value={producto}>
                                        {producto}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <input
                                type="text"
                                name="campo_11"
                                value={nuevaFila.campo_11}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e)} // Detectar Enter en móvil nueva fila
                            />
                        </td>
                        <td>
                            <select
                                name="campo_12"
                                value={nuevaFila.campo_12}
                                onChange={handleNuevaFilaChange}
                                onKeyDown={(e) => handleKeyDown(e)}
                            >
                                <option value="">Seleccionar Origen</option>
                                {opcionesOrigen.map((origen, idx) => (
                                    <option key={idx} value={origen}>
                                        {origen}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <select
                                name="campo_yy"
                                value={nuevaFila.campo_yy}
                                onChange={handleNuevaFilaChange} // Capturar el cambio
                                required
                            >
                                <option value="">Seleccionar Ciudad</option>
                                {opcionesCiudad.map((ciudad, idx) => (
                                    <option key={idx} value={ciudad}>
                                        {ciudad}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Pedidos;
